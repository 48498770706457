import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainHeader from './MainHeader';

const Header = () => {
  const dispatch = useDispatch();

  const categoryList_ = useSelector((state) => state.homePageCategoryList);
  const { loading, error, categoryList } = categoryList_;

  const brandSession_ = useSelector((state) => state.homePageBrand);
  const { brand } = brandSession_;

  const products_ = useSelector((state) => state.productList);
  const { products } = products_;

  const [isMobileSidebar, setIsMobileSidebar] = useState('');

  return (
    <div>
      <div
        className="top-header classicTopbar"
        style={{ backgroundColor: 'rgb(247 247 247)', color: 'black' }}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-left">
              <div className="social-email left-brd d-inline-flex">
                <ul className="list-inline social-icons d-inline-flex align-items-center">
                  <li className="list-inline-item">
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Facebook"
                    >
                      <i
                        className="icon anm anm-facebook-f"
                        style={{ color: 'black' }}
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Twitter"
                    >
                      <i
                        className="icon anm anm-twitter"
                        style={{ color: 'black' }}
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Pinterest"
                    >
                      <i
                        className="icon anm anm-pinterest-p"
                        style={{ color: 'black' }}
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Linkedin"
                    >
                      <i
                        className="icon anm anm-linkedin-in"
                        style={{ color: 'black' }}
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/adcaster.in/"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Instagram"
                    >
                      <i
                        className="icon anm anm-instagram"
                        style={{ color: 'black' }}
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Youtube"
                    >
                      <i
                        className="icon anm anm-youtube"
                        style={{ color: 'black' }}
                      ></i>
                    </a>
                  </li>
                </ul>
                <div className="email d-none d-lg-block">
                  <a href="mailto:info@example.com" style={{ color: 'black' }}>
                    info@adcaster.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center d-none d-md-block">
              Call For
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-right d-flex align-items-center justify-content-end">
              <div className="phone-picker left-brd d-inline-flex align-items-center">
                <div className="phone d-none d-lg-block">
                  <a href="tel:+918078099472" style={{ color: 'black' }}>
                    8078099472
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainHeader
        products={products}
        brand={brand}
        categoryList={categoryList}
        setIsMobileSidebar={setIsMobileSidebar}
      />
      <div
        className={`mobile-nav-wrapper ${isMobileSidebar}`}
        role="navigation"
      >
        <div className="closemobileMenu" onClick={() => setIsMobileSidebar('')}>
          Close Menu <i className="icon anm anm-times-l"></i>
        </div>
        <ul id="MobileNav" className="mobile-nav">
          <li className="mobile-menu-bottom">
            <div className="mobile-links">
              <ul className="list-inline d-inline-flex flex-column w-100">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/products">Products</Link>
                </li>
                <li>
                  <Link to="/products">Category</Link>
                </li>

                <li>
                  <Link to="/products">Brand</Link>
                </li>
                <li>
                  <Link to="#">More</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
