import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../../axios/axios';
import { useDispatch } from 'react-redux';
import { getProductsList } from '../../../actions/ProductPageAction';

export default function MainHeader({
  categoryList,
  brand,
  products,
  setIsMobileSidebar,
}) {
  const dispatch = useDispatch();

  const [isFixed, setIsFixed] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showStyle, setShowStyle] = useState('visible');

  const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [userId, setUserId] = useState(storedUserInfo); // Initialize userId with storedUserInfo
  const [cartList, setCartList] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [productList, setProductList] = useState([]);
  const navigate = useNavigate;

  // console.log(productList)

  const [cart, setCart] = useState([]);
  console.log(cartList);
  console.log(cart);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(`/api/products`);
        // console.log(res.data)
        const { products } = res.data;
        setProductList(products);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Check if the user is logged in
    const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    setIsLoggedIn(!!storedUserInfo);
    fetchCartList();
  }, []);

  const fetchCartList = async () => {
    try {
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));

      const response = await Axios.get(`api/cart/${storedUserInfo._id}`);
      setCart(response.data);
      console.log(response.data);
    } catch (error) {
      console.log('Error fetching cart list:', error.message);
    }
  };

  console.log(cart);

  const handleCartClick = () => {
    if (isLoggedIn) {
      setShowCart(!showCart);
    } else {
      // Redirect to the login page if the user is not logged in
      // You can replace '/login' with the actual path to your login page
      window.location.href = '/login';
    }
  };

  const headerRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const offsetTop = headerRef.current.offsetTop;
        const headerHeight = headerRef.current.clientHeight;

        if (window.scrollY > offsetTop) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }

        if (window.scrollY > 150) {
          setIsSmall(true);
        } else {
          setIsSmall(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logout = () => {
    localStorage.removeItem('userInfo');
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    window.location = `/products?keyword=${searchQuery}`;
  };

  const deleteCart = async (cartId) => {
    try {
      if (window.confirm('Are you sure,Do you want to delete ?')) {
        console.log(cartId);
        if (cartId === undefined || cartId === null) {
          console.error('Invalid cartId:', cartId);
          return;
        }

        const response = await Axios.delete(`api/cart/${cartId}`);

        if (response.status === 200) {
          // Remove the deleted item from the cartList
          setCartList((prevCartList) =>
            prevCartList.filter((cartItem) => cartItem._id !== cartId)
          );
          console.log(`Cart with ID ${cartId} deleted successfully`);
          // You may update local storage here if necessary
        } else {
          console.log('Failed to delete cart:', response.data.message);
        }
      }
    } catch (error) {
      console.error('Error deleting cart:', error.message);
    }
    fetchCartList();
  };

  return (
    <header
      //   className="header classicHeader d-flex align-items-center header-4 header-fixed"
      style={{ backgroundColor: 'white' }}
      className={`header ${isFixed ? 'is-fixed' : ''} ${isSmall ? 'is-small animated fadeIn' : ''
        }`}
    >
      <div className="container-fluid" ref={headerRef}>
        <div className="row">
          <div className="col-1 col-sm-1 col-md-1 col-lg-5 align-self-center d-menu-col hdr-menu-left menu-position-left">
            <nav className="navigation" id="AccessibleNav">
              <ul id="siteNav" className="site-nav medium left">
                <li className="lvl1 parent dropdown">
                  <Link to="/">
                    Home <i className="icon anm anm-angle-down-l"></i>
                  </Link>
                </li>

                <li className="lvl1 parent megamenu">
                  <a href="/products">
                    Products <i className="icon anm anm-angle-down-l"></i>
                  </a>
                  <div className="megamenu style1">
                    <ul className="row grid--uniform mmWrapper">
                      {/* ///////////////////////////////////////////////// */}
                      {productList?.map((product, index) => (
                        <li key={index} className="lvl-1 col-md-3 col-lg-3">
                          <a href="#;" className="site-nav lvl-1 menu-title">
                            {/* Product Page */}
                          </a>
                          <ul className="subLinks">
                            <Link to={`/product/${product._id}`}>
                              <li className="lvl-2">{product.name}</li>
                            </Link>
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li className="lvl1 parent megamenu">
                  <a href="#">
                    Category <i className="icon anm anm-angle-down-l"></i>
                  </a>
                  <div className="megamenu style2">
                    <ul className="row mmWrapper">
                      {categoryList?.categories?.map((category, index) => (
                        <li key={index} className="lvl-1 col-md-3 col-lg-3">
                          <ul className="subLinks">
                            <li className="lvl-2">
                              <Link to={`/products?category=${category._id}`}>
                                {category.categoryName}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li className="lvl1 parent megamenu">
                  <a href="#">
                    Brands <i className="icon anm anm-angle-down-l"></i>
                  </a>
                  <div className="megamenu style2">
                    <ul className="row mmWrapper">
                      {brand?.brands?.map((brand, index) => (
                        <li className="lvl-1 col-md-3 col-lg-3">
                          <ul className="subLinks">
                            <Link to={`/products?brand=${brand._id}`}>
                              <li className="lvl-2">{brand.brandName}</li>
                            </Link>
                          </ul>
                        </li>
                      ))}


                    </ul>
                  </div>
                </li>
                <li className="lvl1 parent megamenu">
                  <a href="#">
                    More
                  </a>
                  <div className="megamenu style2">
                      <li >
                          <Link to="/availabledates">Product Availability</Link>

                      </li>
                      <li >
                          <Link to="/packavail">Package Availability</Link>

                      </li>


                  </div>
                </li>
              </ul>
            </nav>
          </div>
          {/* <li className="lvl1 parent megamenu">
                        <a href="#">
                          Brands <i className="icon anm anm-angle-down-l"></i>
                        </a>

                        <Link to="/availabledates">Availability</Link>

                      </li> */}

          <div className="logo col-4 col-sm-4 col-md-4 col-lg-2 align-self-center">
            <a className="logoImg" href="/">
              <img
                className="mx-lg-auto default-logo"
                src="/logo.png"
                alt="Hema Multipurpose Html Template"
                title="Hema Multipurpose Html Template"
                width="149"
                height="69"
              />
              {/* <span className="logo-txt d-none">Hema</span> */}
            </a>
          </div>

          <div className="col-8 col-sm-8 col-md-8 col-lg-5 align-self-center icons-col text-right">
            <div className="search-parent iconset">
              <div className="site-search" title="Search">
                <a
                  href="#;"
                  className="search-icon clr-none"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#search-drawer"
                >
                  <i className="hdr-icon icon anm anm-search-l"></i>
                </a>
              </div>
              <div
                className="search-drawer offcanvas offcanvas-top"
                tabIndex="-1"
                id="search-drawer"
              >
                <div className="container">
                  <div className="search-header d-flex-center justify-content-between mb-3">
                    <h3 className="title m-0">What are you looking for?</h3>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="search-body">
                    <form
                      onSubmit={handleSubmit}
                      className="form minisearch"
                      id="header-search"
                      action="#"
                      method="get"
                    >
                      <div className="d-flex searchField">
                        <div className="search-category"></div>
                        <div className="input-box d-flex fl-1">
                          <input
                            type="text"
                            className="input-text border-start-0 border-end-0"
                            placeholder="Search for products..."
                            value={searchQuery}
                            onChange={handleInputChange}
                          />
                          <button
                            type="submit"
                            className="action search d-flex-justify-center btn btn-primary rounded-start-0"
                          >
                            <i className="icon anm anm-search-l"></i>
                          </button>
                        </div>
                      </div>

                      {/* <div className="popular-searches d-flex-justify-center mt-3">
                        <span className="title fw-600">Trending Now:</span>
                        <div className="d-flex-wrap searches-items">
                          <a className="text-link ms-2" href="#">
                            Camera,
                          </a>
                          <a className="text-link ms-2" href="#">
                            Laptop,
                          </a>
                          <a className="text-link ms-2" href="#">
                            Electronic
                          </a>
                        </div>
                      </div> */}

                      <div className="search-products">
                        <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                          <li className="item empty w-100 text-center text-muted d-none">
                            You don't have any items in your search.
                          </li>
                          <li className="item">
                            {/* <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <a
                                  className="item-link"
                                  href="product-layout1.html"
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src="assets/images/products/120x170.jpg"
                                    src="assets/images/products/120x170.jpg"
                                    alt="image"
                                    title="product"
                                    width="120"
                                    height="170"
                                  />
                                </a>
                              </div>
                              {/* <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <a
                                    className="item-title"
                                    href="product-layout1.html"
                                  >
                                    Digital SLR Camera
                                  </a>
                                </div>
                                <div className="product-price">
                                  <span className="old-price">$114.00</span>
                                  <span className="price">$99.00</span>
                                </div>
                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star-o"></i>
                                  <i className="icon anm anm-star-o"></i>
                                  <span className="caption hidden ms-2">
                                    3 reviews
                                  </span>
                                </div>
                              </div> */}
                            {/* </div> */}
                          </li>
                          <li className="item">
                            {/* <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <a
                                  className="item-link"
                                  href="product-layout1.html"
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src="assets/images/products/120x170.jpg"
                                    src="assets/images/products/120x170.jpg"
                                    alt="image"
                                    title="product"
                                    width="120"
                                    height="170"
                                  />
                                </a>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <a
                                    className="item-title"
                                    href="product-layout1.html"
                                  >
                                    Instax Instant Camera
                                  </a>
                                </div>
                                <div className="product-price">
                                  <span className="price">$128.00</span>
                                </div>
                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <span className="caption hidden ms-2">
                                    9 reviews
                                  </span>
                                </div>
                              </div>
                            </div> */}
                          </li>
                          <li className="item">
                            {/* <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <a
                                  className="item-link"
                                  href="product-layout1.html"
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src="assets/images/products/120x170.jpg"
                                    src="assets/images/products/120x170.jpg"
                                    alt="image"
                                    title="product"
                                    width="120"
                                    height="170"
                                  />
                                </a>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <a
                                    className="item-title"
                                    href="product-layout1.html"
                                  >
                                    EOS Mirrorless Camera
                                  </a>
                                </div>
                                <div className="product-price">
                                  <span className="price">$99.00</span>
                                </div>
                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star"></i>
                                  <i className="icon anm anm-star-o"></i>
                                  <span className="caption hidden ms-2">
                                    30 reviews
                                  </span>
                                </div>
                              </div>
                            </div> */}
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="account-parent iconset">
              <div className="account-link" title="Account">
                <i className="hdr-icon icon anm anm-user-al"></i>
              </div>
              <div id="accountBox">
                <div className="customer-links">
                  <ul className="m-0">
                    <li>
                      <Link to="/login">
                        <i className="icon anm anm-sign-in-al"></i>Login
                      </Link>
                    </li>
                    <li>
                      <a href="Signup">
                        <i className="icon anm anm-user-al"></i>Register
                      </a>
                    </li>
                    <li>
                      <Link to="/myorders">
                        <i className="icon anm anm-random-r"></i>My Orders
                      </Link>
                    </li>
                    <li>
                      <Link to="/cart">
                        <i className="icon anm anm-heart-l"></i>My Cart
                      </Link>
                    </li>
                    <li>
                      <Link to="/profileview">
                        <i class="icon anm anm-user-cil"></i>My Account
                      </Link>
                    </li>
                    {/* <li>
                      <a href="compare-style1.html">
                        <i className="icon anm anm-random-r"></i>Compare
                      </a>
                    </li> */}
                    <li>
                      <Link to="/login" onClick={() => logout()}>
                        <i className="icon anm anm-sign-out-al"></i>Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 
            <div className="wishlist-link iconset" title="Wishlist">
              <a href="wishlist-style1.html">
                <i className="hdr-icon icon anm anm-heart-l"></i>
                <span className="wishlist-count">5</span>
              </a>
            </div> */}
            {showCart === true ? (
              <div className="  iconset" title="Cart">
                <a
                  className={`minicart-right-drawer offcanvas offcanvas-end show`}
                  aria-modal="true"
                  role="dialog"
                  style={{ visibility: showStyle }}
                >
                  <div>
                    <div id="cart-drawer" class="block block-cart">
                      <div class="minicart-header">
                        <div class="d-flex bd-highlight">
                          <div class="p-2 flex-fill bd-highlight">
                            <h4 class="fs-6">
                              Your cart ({cart?.product?.length} Items)
                            </h4>
                          </div>

                          <div class="p-2 flex-fill bd-highlight">
                            <button
                              class="close-cart border-0"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => setShowCart(false)}
                            >
                              <i
                                class="icon anm anm-times-r"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Close"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* ///////////////////here cartlist map here */}
                      <div class="minicart-content">
                        {cart?.product?.map((cartItem) => (
                          <ul class="m-0 clearfix">
                            <li class="item d-flex justify-content-center align-items-center">
                              <a
                                class="product-image rounded-0"
                                href="product-layout1.html"
                              >
                                <img
                                  class="rounded-0 blur-up lazyload"
                                  src={
                                    cartItem?.proPhoto != ''
                                      ? cartItem?.proPhoto
                                      : '/assets/images/products/noimage.png'
                                  }
                                  alt="product"
                                  title="Product"
                                  width="120"
                                  height="170"
                                />
                              </a>
                              <div class="product-details">
                                <a
                                  class="product-title"
                                  href="product-layout1.html"
                                >
                                  <span style={{ fontSize: '1rem' }}>{cartItem?.productname}</span>
                                </a>
                                <div class="variant-cart my-2">
                                  QNT :{cartItem?.qnty}
                                </div>
                                <div class="priceRow">
                                  <div class="product-price">
                                    <span style={{ fontSize: '1rem' }}>Price :</span>
                                    <span class="price">{cartItem?.price}</span><br />
                                    <span class="total">
                                      <span style={{ fontSize: '1rem' }}>Total Amount :</span>
                                      {cartItem?.totalAmount}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="qtyDetail text-center">
                                <a
                                  href="#"
                                  class="remove"
                                  onClick={() => deleteCart(cartItem.cartId)}
                                >
                                  <i
                                    class="icon anm anm-times-r"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Remove"
                                  ></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        ))}
                      </div>

                      <div class="minicart-bottom">
                        {/* <div class="subtotal clearfix my-3">
                          <div class="totalInfo clearfix mb-1 d-none">
                            <span>Shipping:</span>
                            <span class="item product-price">$10.00</span>
                          </div>
                          <div class="totalInfo clearfix mb-1 d-none">
                            <span>Tax:</span>
                            <span class="item product-price">$0.00</span>
                          </div>
                          <div class="totalInfo clearfix">
                            <span>Total:</span>
                            <span class="item product-price">$163.00</span>
                          </div>
                        </div> */}
                        {/* <div class="agree-check customCheckbox">
                          <input
                            id="prTearm"
                            name="tearm"
                            type="checkbox"
                            value="tearm"
                            required
                          />
                          <label for="prTearm"> I agree with the </label>
                          <a href="#" class="ms-1 text-link">
                            Terms &amp; conditions
                          </a>
                        </div> */}
                        <div class="minicart-action d-flex mt-3">
                          <a
                            href="/cart"
                            className=" proceed-to-checkout btn btn-primary w-100 me-1"
                          >
                            Check Out
                          </a>
                          {/* <a
                            href="/cart"
                            class="cart-btn btn btn-secondary w-50 ms-1"
                          >
                            View Cart
                          </a> */}
                        </div>
                      </div>
                    </div>{' '}
                  </div>
                </a>
              </div>
            ) : (
              <div
                className="  iconset position-relative"
                title="Cart"
                onClick={handleCartClick}
              // onClick={(e) => setShowCart(true)}
              >
                <a
                  aria-modal="true"
                  role="dialog"
                  style={{ visibility: showStyle }}
                >
                  <i class="hdr-icon icon anm anm-cart-l"></i>
                  {/* <span class="cart-count">2</span> */}
                </a>

                <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                  {cart?.product?.length}
                  <span className='visually-hidden'>unread messages</span>
                </span>

                {!isLoggedIn && (
                  <div>
                    {/* <p>Please login to access the cart.</p> */}
                    <Link to="/login">{/* <button>Login</button> */}</Link>
                  </div>
                )}
              </div>
            )}

            <button
              type="button"
              className="iconset pe-0 menu-icon js-mobile-nav-toggle mobile-nav--open d-lg-none"
              title="Menu"
              onClick={() => setIsMobileSidebar('active')}
            >
              <i className="hdr-icon icon anm anm-times-l"></i>
              <i className="hdr-icon icon anm anm-bars-r"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}