import React, { useEffect, useState } from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import Axios from '../../axios/axios';

import CartList from '../Cart/CartList';
import Product from '../Product/Products';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function Checkout() {
  const navigate = useNavigate();

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostCode] = useState('');

  // const [cartCheckOut, setcartCheckOut] = useState(null);
  const [cart, setCart] = useState([]);
  console.log(cart);

  const [cartCheckOut, setcartCheckOut] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [advance, setAdvance] = useState(0);
  const [orderAmount, setOrderAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [product, setProduct] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [userId, setUserId] = useState('');

  console.log(cart);

  useEffect(() => {

    fetchCart();
  }, []);
  console.log(cart,"cartttttttttttttttttttttttt");

  const fetchCart = async () => {
    try {
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));

      console.log(storedUserInfo._id);
      const response = await Axios.get(`api/cart/${storedUserInfo._id}`);
      setCart(response.data);
      console.log(response.data);
    } catch (error) {
      console.log('Error fetching cart list:', error.message);
    }
  };


  const deleteCart = async (cartId) => {
    try {
      console.log(cartId, 'cartid before confirmation');

      if (window.confirm('Are you sure,Do you want to delete ?')) {
        console.log(cartId, 'cartid after confirmation');

        if (cartId === undefined || cartId === null) {
          return;
        }

        const response = await Axios.delete(`api/cart/${cartId}`);
        console.log('deleted cart item');

        if (response.status === 200) {
          // Remove the deleted cart item from the cart state
          // setCart(prevCart => prevCart.filter(cartItem => cartId !== cartItem.cartId));
          setCart((prevCart) => {
            if (!Array.isArray(prevCart)) {
              console.error('prevCart is not an array:', prevCart);
              return [];
            }
            return prevCart.filter((cartItem) => cartId !== cartItem.cartId);
          });

          console.log(`Cart with ID ${cartId} deleted successfully`);
          const fetchCart = async () => {
            try {
              const storedUserInfo = JSON.parse(
                localStorage.getItem('userInfo')
              );

              const response = await Axios.get(
                `api/cart/${storedUserInfo._id}`
              );
              setCart(response.data);
              console.log(response.data);
            } catch (error) {
              console.log('Error fetching cart list:', error.message);
            }
          };
          fetchCart();
        } else {
          console.log('Failed to delete cart:', response.data.message);
        }
      }
    } catch (error) {
      console.error('Error deleting cart:', error.message);
    }
  };
  const subtotal = cart?.product?.reduce(
    (total, item) => total + item.totalAmount,
    0
  );
  console.log(subtotal);

  const orderCartData = cart?.product?.map((cartItem) => ({
    product: cartItem?.product,
    // totalAmount: cartItem?.orderItems[0].totalAmount,
    startDate: cartItem?.startDate,
    endDate: cartItem?.endDate,
    price: cartItem?.price,
    days: cartItem?.days,
    qnty: cartItem?.quantity,
    prodPrice: cartItem?.totalAmount,
    packageId: cartItem?.packageId
  }));

  console.log(subtotal);
  const [loading, setLoading] = useState(false);

  // checkout
  const handleCheckout = async () => {
    setLoading(true);
    // try {
    //   const customerData = JSON.parse(localStorage.getItem('userInfo'));
    //   const customer = customerData._id;

    //   const orderAmount = orderCartData.reduce(
    //     (total, item) => total + item.prodPrice,
    //     0
    //   );

    //   const data = {
    //     customer,
    //     selectedItems: orderCartData,
    //     advance: 0,
    //     orderAmount: calculateTotal(),
    //     discount: 0,

    //   };

    //   console.log(data);

    //   const response = await Axios.post(`api/order`, data);
    //   console.log(response.data);

    //   if (response.data) {
    //     setcartCheckOut(response.data);
    //     setTimeout(() => {
    //       // navigate('/')
    //     }, 1000);
    //   }
    //   toast.success('order placed successfully');
    // } catch (error) {
    //   console.error('Error during checkout:', error.message);
    //   console.error(
    //     'Response data:',
    //     error.response ? error.response.data : 'No response data'
    //   );
    //   toast.error('order not placed');
    //   setLoading(false);
    // }
  };

  console.log(cart)
  const calculateTotal = () => {
    let total = 0;
    cart?.product?.map((dt) => {
      let count = dt.quantity * dt.days;

      total = total + dt.price * count;
    });
    return total;
  };

  return (
    <body className="template-index index-demo4">
      <div className="page-wrapper">
        <div className="classic-topHeader">
          <Header />

          <div id="page-content">
            <div class="page-header text-center">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                    <div class="page-title">
                      <h1>Checkout </h1>
                    </div>

                    <div class="breadcrumbs">
                      Home
                      <span class="main-title">
                        <i class="icon anm anm-angle-right-l"></i>
                        Checkout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <form class="checkout-form" method="post" action="#">
                <div class="row">

                </div>

                <div class="row">
                  <div class="col-lg-8 col-md-7 col-sm-12 col-12 mb-4 mb-md-0">
                    <div class="block order-summary">
                      <div class="block-content">
                        <h3 class="title mb-3 text-uppercase">Order Summary</h3>
                        <div class="table-responsive-sm table-bottom-brd order-table">
                          <table class="table table-hover align-middle mb-0">
                            <thead>
                              <tr>
                                <th class="action">&nbsp;</th>
                                <th class="text-start">Image</th>
                                <th class="text-start proName">Product</th>
                                {/* <th class="text-center">Qty</th> */}
                                <th class="text-center">Price</th>
                                <th class="text-center">Quantity</th>
                                <th class="text-center">Start Date</th>
                                <th class="text-center">Days</th>
                                <th class="text-center">Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cart?.product?.map((cartItem) => (
                                <tr>
                                  <td class="text-center cart-delete">
                                    <a
                                      href="#"
                                      onClick={() => deleteCart(cartItem.cartId)}
                                      class="cart-remove remove-icon position-static"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Remove to Cart"
                                    >
                                      <i class="icon anm anm-times-r"></i>
                                    </a>
                                  </td>
                                  <td class="text-start">
                                    <img
                                      class="rounded-0 blur-up lazyload"
                                      src={
                                        cartItem?.proPhoto != ''
                                          ? cartItem?.proPhoto
                                          : '/assets/images/products/noimage.png'
                                      }
                                      alt="product"
                                      title="product"
                                      width="120"
                                      height="170"
                                    />
                                  </td>
                                  <td class="text-start proName">
                                    <div class="list-view-item-title">
                                      {cartItem?.productname}
                                    </div>
                                  </td>
                                  {/* <td class="text-center">2</td> */}
                                  <td class="text-center">{cartItem?.price}</td>
                                  <td class="text-center">
                                    {cartItem?.quantity}
                                  </td>
                                  <td class="text-center">
                                    {cartItem?.startDate}
                                  </td>
                                  <td class="text-center">{cartItem?.days}</td>
                                  <td class="text-center">
                                    {cartItem?.price *
                                      cartItem?.days *
                                      cartItem?.quantity}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-5 col-sm-12 col-12">
                    <div class="cart-info">
                      <div class="cart-order-detail cart-col">
                        <div class="row g-0 border-bottom pb-2">
                          {/* <span class="col-6 col-sm-6 cart-subtotal-title">
                            <strong>Subtotal</strong>
                          </span> */}
                          {/* <span class="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                            <span class="money">{subtotal}</span>
                          </span> */}
                        </div>
                        <div class="row g-0 border-bottom py-2">
                          {/* <span class="col-6 col-sm-6 cart-subtotal-title">
                            <strong>Coupon Discount</strong>
                          </span> */}
                          {/* <span class="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                            <span class="money">-----</span>
                          </span> */}
                        </div>

                        <div class="row g-0 pt-2">
                          <span class="col-6 col-sm-6 cart-subtotal-title fs-6">
                            <strong>Total</strong>
                          </span>
                          <span class="col-6 col-sm-6 cart-subtotal-title fs-5 cart-subtotal text-end text-primary">
                            <b class="money">₹ {calculateTotal()}</b>
                          </span>
                        </div>

                        <button
                          id="cartCheckout"
                          class={`btn btn-lg my-4 checkout w-100 ${loading ? 'loading' : ''
                            }`}
                          onClick={() => handleCheckout()}
                          disabled={loading === true ? true : false}
                        >
                          {loading ? 'Processing...' : 'Place Order'}
                        </button>

                        <div class="paymnet-img text-center">
                          <img
                            src="assets/images/icons/safepayment.png"
                            alt="Payment"
                            width="299"
                            height="28"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </div>{' '}
      </div>
    </body>
  );
}
