import React, { Fragment } from 'react';

import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';
import logo from './logo.png';
import { dateFormat } from './Utilss';

const borderColor = '#90e5fc';
const blankRows = Array(6).fill(0);

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 150,
    height: 96,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titleContainerName: {
    flexDirection: 'row',
    marginTop: -20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  reportTitleName: {
    color: 'black',
    letterSpacing: 0,
    fontSize: 8,
    textAlign: 'center',
  },
  invoiceNoContainer: {
    flexDirection: 'row',
    marginTop: 36,
    justifyContent: 'flex-end',
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold',
  },
  label: {
    width: 60,
  },
  labelWithRed: {
    width: 60,
    color: 'red',
  },
  headerContainer: {
    marginTop: 70,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  description: {
    width: '60%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  itemname: {
    width: '60%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
  },
  qty: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: '15%',
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  description: {
    width: '60%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  rate: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  amount: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold',
  },
  description: {
    width: '85%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
  titleContainer: {
    flexDirection: 'row',
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  reportTitleTerms: {
    fontSize: 8,
    textAlign: 'center',
  },
  termsAndConditions: {
    marginTop: 20,
    fontSize: 7,
    textAlign: 'left',
  },
  bulletList: {
    marginTop: 10,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  bullet: {
    marginRight: 5,
    fontSize: 10,
  },
  listItemText: {
    fontSize: 7,
  },
  rowA: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 36,
  },
  columnA: {
    flex: 1, // Make each column take equal space
    marginRight: 10, // Add margin between columns
  },
});

const rows = blankRows.map((x, i) => (
  <View style={styles.row} key={`BR${i}`}>
    <Text style={styles.description}> </Text>
    <Text style={styles.qty}> </Text>
    <Text style={styles.qty}> </Text>
    <Text style={styles.amount}> </Text>
  </View>
));

// Create Document Component
const PDF = ({
  products,
  customerName,
  customerNumber,
  address,
  invoiceId,
  orderDate,
  advance,
  amount,
  items,
  discount,
  isDeliveredPrint,
  specialPrice
}) => {
  const productName = (proId) => {
    let data = products?.filter((data) => data._id === proId);
    console.log(data?.[0]?.name)
    if (data) {
      return data?.[0]?.name;

    }

  };
  console.log(products)

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const formatDateComponent = (component) => component.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = formatDateComponent(date.getMonth() + 1);
    const day = formatDateComponent(date.getDate());

    const hours = formatDateComponent(date.getHours());
    const minutes = formatDateComponent(date.getMinutes());
    const seconds = formatDateComponent(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const itemsRow = items?.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={styles.qty}>{index + 1}</Text>

      <Text style={styles.itemname}> {productName(item.product)}</Text>

      <Text style={styles.rate}> {item.prodPrice}</Text>
      <Text style={styles.qty}>{item.qnty}</Text>
      <Text style={styles.qty}>{item.days}</Text>

      <Text style={styles.rate}> {item.amount}</Text>
    </View>
  ));
  // console.log(itemsRow)
  console.log(items)

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <View style={styles.titleContainerName}>
          <Text style={styles.reportTitleName}>
            {' '}
            Mini Road Changuvetty, Kottakkal, Malappuram - (+91)8078099472
          </Text>
        </View>
        <View style={styles.rowA}>
          <View style={styles.columnA}>
            <Text style={styles.columnText}>
              {' '}
              <View style={styles.invoiceNoContainer}>
                <Text style={styles.billTo}>Billed To: {'\n'}</Text>

                <Text>
                  {customerName} {'\n'}
                </Text>
                <Text>
                  {address} {'\n'}
                </Text>
                <Text>
                  {customerNumber} {'\n'}
                </Text>
              </View>
            </Text>
          </View>
          <View style={styles.columnA}>
            <Text style={styles.columnText}></Text>
          </View>
          <View style={styles.columnA}>
            <Text style={styles.columnText}>
              {' '}
              <Fragment>
                <View style={styles.invoiceNoContainer}>
                  <Text style={styles.label}>Invoice No.</Text>
                  <Text style={styles.invoiceDate}>
                    {`ADC_${invoiceId}`} {'\n'}
                  </Text>
                </View>
                <View style={styles.invoiceDateContainer}>
                  {/* <Text style={styles.label}>Date: </Text> */}
                  <Text>{formatDateTime(orderDate)}</Text>
                </View>
              </Fragment>
            </Text>
            {isDeliveredPrint === true && (
              <Text style={styles.columnText}>
                {' '}
                <View style={styles.invoiceDateContainer}>
                  <Text style={styles.labelWithRed}>Delivered </Text>
                </View>
              </Text>
            )}
          </View>
        </View>

        <View style={styles.tableContainer}>
          <View style={styles.container}>
            <Text style={styles.qty}>No</Text>

            <Text style={styles.itemname}>Item</Text>
            <Text style={styles.amount}>Price</Text>
            <Text style={styles.qty}>Qty</Text>
            <Text style={styles.qty}>Days</Text>

            <Text style={styles.amount}>Total</Text>
          </View>
          <Fragment>{itemsRow}</Fragment>
          <Fragment>{rows}</Fragment>
          <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}> {amount}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>Special Price</Text>
            <Text style={styles.total}> {specialPrice}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>Advance</Text>
            <Text style={styles.total}> {advance}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>Discount</Text>
            <Text style={styles.total}> {discount}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>Balance</Text>
            <Text style={styles.total}>
              {' '}
              {amount - (parseInt(advance) + parseInt(discount))}
            </Text>
          </View>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>Thank you for your business</Text>{' '}
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitleTerms}> Terms & Conditions</Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.bullet}>•</Text>
          <Text style={styles.listItemText}>
            {' '}
            It only issue or damage is loud in the returned product the client
            should give additional expenditure
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.bullet}>•</Text>
          <Text style={styles.listItemText}>
            {' '}
            * data lost by any technical issue we will not take the
            responsibility
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.bullet}>•</Text>
          <Text style={styles.listItemText}>
            {' '}
            * in case Returning time will extend the customer should inform
            the office staff other wise customer shoald pay an extra charge
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDF;