import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import banner from './banner.jpg';
import bannerMobile from './10101.jpg';
import Header from './components/Layout/Header/Header';
import Home from './pages/Home/Home';
import Products from './pages/Product/Products';
import Product from './pages/Product/Product';

import Checkout from './pages/Checkout/Checkout';
import Login from './pages/Login/Login';
import SignUp from './pages/Login/Signup';
import Cart from './pages/Cart/CartList';
import MyOrders from './pages/Cart/MyOrders';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './pages/Profile/Profile';
import ProfileView from './pages/Profile/ProfileView';
import OrderSuccessPage from './pages/Cart/OrderSuccessPage';
import AvailabilityCalender from './pages/Product/AvailabilityCalender'
import Package from './pages/Package/Package';
import PackageSingle from './pages/Package/PackageSingle'
import Availability from './pages/Package/Availability'
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/products" element={<Products />}></Route>
        
        <Route exact path="/packavail" element={<Availability />}></Route>
        <Route exact path="/package" element={<Package />}></Route>
        <Route exact path="/packagesingle/:id" element={<PackageSingle />}></Route>
        <Route exact path="/product/:id" element={<Product />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/signup" element={<SignUp />}></Route>
        <Route exact path="/cart" element={<Cart />}></Route>
        <Route exact path="/checkout" element={<Checkout />}></Route>
        <Route exact path="/myorders" element={<MyOrders />}></Route>
        <Route exact path="/profile" element={<Profile />}></Route>
        <Route exact path="/profileview" element={<ProfileView />}></Route>
        <Route exact path="/ordersummary" element={<OrderSuccessPage />}></Route>
        <Route exact path="/availabledates" element={<AvailabilityCalender />}></Route>
      </Routes>
      <div>
        <ToastContainer />
      </div>
    </Router>

    // <body className="template-index index-demo4">
    //   <div className="page-wrapper">
    //     <div className="classic-topHeader">
    //       <Header />
    //       <Home />
    //     </div>
    //   </div>
    // </body>
    // <div className="image-container">
    //   <img src={banner} width="100%" className="desktop-image" />
    //   <img src={bannerMobile} width="100%" className="mobile-image" />
    //   <a
    //     href="https://api.whatsapp.com/send?phone=919778069953" // Replace with your desired WhatsApp API link
    //     className="whatsapp-button"
    //     data-tip="Open WhatsApp"
    //     target="_blank" // Open WhatsApp link in a new tab/window
    //     rel="noopener noreferrer" // Recommended for security reasons
    //   >
    //     <div className="whatsapp-button" data-tip="Open WhatsApp">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="24"
    //         height="24"
    //         fill="currentColor"
    //         className="bi bi-whatsapp"
    //         viewBox="0 0 16 16"
    //         style={{ color: 'white' }}
    //       >
    //         <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
    //       </svg>
    //     </div>
    //   </a>
    // </div>
  );
}

export default App;
