import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getNewArrivals } from '../../actions/HomePageActions';
import './style.css'

export default function NewArrivals() {
  const dispatch = useDispatch();

  const NewArrivals = useSelector((state) => state.homePageNewArrivals);
  const { loading, error, newArrivals } = NewArrivals;

  useEffect(() => {
    dispatch(getNewArrivals());
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  return (
    // <section class="section product-banner-slider">
    //  <div className='row'>
    //   <div className='col-lg-4'>
    //   <div class="container">
    //     <div class="section-header style2 d-flex-center justify-content-sm-between">
    //       <div class="sec6ion-header-left text-start">
    //         <h2>New Arrivals</h2>
    //         <p>Shop our new arrivals from established brands</p>
    //       </div>


    //     </div>

    //     <div class="row">
    //       <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
    //         <div class="ctg-bnr-wrap one position-relative h-100">

    //           <div class="ctg-image ratio ratio-1x1 h-100">

    //             <img
    //               class="blur-up lazyload"
    //               data-src="assets/images/collection/producs_home_page.jpg "
    //               src="assets/images/collection/products_home_page.jpg"
    //               alt="collection"
    //               width="390"
    //               height="483"
    //             />

    //           </div>
    //           <Link to="/products" class="btn btn-primary">
    //             View More <i class="icon anm anm-arw-right ms-2"></i>
    //           </Link>
    //           <div class="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
    //             <h2 class="ctg-title text-white m-0">
    //             </h2>
    //             <p class="ctg-des mt-3 mb-4">
    //               Shop our products check what's new in our collections.
    //             </p>
    //             Explore Now <i class="icon anm anm-arw-right ms-2"></i>
    //           </div>
    //         </div>
    //       </div>



    //     </div>
    //   </div>
    //   </div>
    //   <div className='col-lg-4'>
    //   <div class="container">
    //     <div class="section-header style2 d-flex-center justify-content-sm-between">
    //       <div class="section-header-left text-start">
    //         <h2>Packages</h2>
    //         <p>Shop Package</p>
    //       </div>


    //     </div>

    //     <div class="row">
    //       <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
    //         <div class="ctg-bnr-wrap one position-relative h-100">
    //           <div class="ctg-image ratio ratio-1x1 h-100">
    //             <img
    //               class="blur-up lazyload"
    //               data-src="assets/images/collection/producs_home_page.jpg "
    //               src="assets/images/collection/products_home_page.jpg"
    //               alt="collection"
    //               width="390"
    //               height="483"
    //             />

    //           </div>
    //           <Link to="/package" class="btn btn-primary">
    //             View More <i class="icon anm anm-arw-right ms-2"></i>
    //           </Link>
    //           <div class="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
    //             <h2 class="ctg-title text-white m-0">
    //             </h2>
    //             <p class="ctg-des mt-3 mb-4">
    //               Shop our Package check what's new in our collections.
    //             </p>
    //             Explore Now <i class="icon anm anm-arw-right ms-2"></i>
    //           </div>
    //         </div>
    //       </div>


    //     </div>
    //   </div>
    //   </div>
    //   <div className='col-lg-4'>
    //   <div class="container">
    //     <div class="section-header style2 d-flex-center justify-content-sm-between">
    //       <div class="section-header-left text-start">
    //         <h2>Availability</h2>
    //         <p>Check Now</p>
    //       </div>


    //     </div>

    //     <div class="row">
    //       <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
    //         <div class="ctg-bnr-wrap one position-relative h-100">
    //           <div class="ctg-image ratio ratio-1x1 h-100">
    //             <img
    //               class="blur-up lazyload"
    //               data-src="assets/images/collection/producs_home_page.jpg "
    //               src="assets/images/collection/products_home_page.jpg"
    //               alt="collection"
    //               width="390"
    //               height="483"
    //             />

    //           </div>

    //           <div class="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
    //             <h2 class="ctg-title text-white m-0">
    //             </h2>

    //             Explore Now <i class="icon anm anm-arw-right ms-2"></i>
    //           </div>
    //         </div>
    //       </div>


    //     </div>
    //   </div>
    //   </div>
    //  </div>

    // </section>

    <section className="section product-banner-slider">
      <div className="row">
        {/* New Arrivals */}

        <div className="col-lg-4">
          <div className="container">

            <div className="section-header style2 d-flex-center justify-content-sm-between">
              <div className="section-header-left text-start">
                <h2>New Arrivals</h2>
                <p>Shop our new arrivals from established brands</p>
              </div>
              <Link to="/products" className="btn btn-primary">
                View More <i className="icon anm anm-arw-right ms-2"></i>
              </Link>
            </div>
            <div className="ctg-bnr-wrap one position-relative h-100">
              <div className="ctg-image ratio ratio-1x1 h-100">
                <img
                  className="blur-up lazyload"
                  data-src="assets/images/collection/arrivals.jpg"
                  src="assets/images/collection/arrivals.jpg"
                  alt="collection"
                  width="390"
                  height="483"
                />
              </div>

              <div className="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
                <p className="ctg-des mt-3 mb-4">
                  Shop our Products check what's new in our collections.
                </p>
                Explore Now <i className="icon anm anm-arw-right ms-2"></i>
              </div>
            </div>
          </div>
        </div>

        {/* Packages */}
        <div className="col-lg-4">
          <div className="container">
            <div className="section-header style2 d-flex-center justify-content-sm-between">
              <div className="section-header-left text-start">
                <h2>Packages</h2>
                <p>Shop our new Package from established brands</p>

              </div>
              <Link to="/package" className="btn btn-primary">
                View More <i className="icon anm anm-arw-right ms-2"></i>
              </Link>
            </div>
            <div className="ctg-bnr-wrap one position-relative h-100">
              <Link to="/package" >
                <div className="ctg-image ratio ratio-1x1 h-100">
                  <img
                    className="blur-up lazyload"
                    data-src="assets/images/collection/darkimg.jpg"
                    src="assets/images/collection/darkimg.jpg"
                    alt="collection"
                    width="390"
                    height="483"
                  />
                </div>
              </Link>

              <div className="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
                <p className="ctg-des mt-3 mb-4">
                  Shop our Package check what's new in our collections.
                </p>
                Explore Now <i className="icon anm anm-arw-right ms-2"></i>
              </div>
            </div>
          </div>
        </div>

        {/* Availability */}
        <div className="col-lg-4">
          <div className="container">
            <div className="section-header style2 d-flex-center justify-content-sm-between">
              <div className="section-header-left text-start">
                <h2>Availability</h2>
                <p>Check Product / Package Availability </p>
              </div>
            </div>
            <div className="ctg-bnr-wrap one position-relative h-100" onClick={handleOpenModal}>
              <div className="ctg-image ratio ratio-1x1 h-100">
                <img
                  className="blur-up lazyload"
                  data-src="assets/images/collection/producs_home_page.jpg"
                  src="assets/images/collection/products_home_page.jpg"
                  alt="collection"
                  width="390"
                  height="483"
                />
              </div>
              <div className="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
                <p className="ctg-des mt-3 mb-4">
                  Explore Now <i className="icon anm anm-arw-right ms-2"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Choose Option</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Button variant="primary" className="me-2" as={Link} to="/packavail">
            Package Availability
          </Button>
          <Button variant="secondary" as={Link} to="/availabledates">
            Product Availability
          </Button>
        </Modal.Body>
      </Modal>
    </section>

  );
}














// const counter = useSelector(state => state.counter);
