//this is product list  page

import React, { useState, useEffect } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/Header';
import { getProductsList } from '../../actions/ProductPageAction';
import { Link } from 'react-router-dom';

import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { brandSession } from '../../actions/HomePageActions';
import { getCategoryList } from '../../actions/HomePageActions';
import { useLocation } from 'react-router-dom';
import Axios from '../../axios/axios'



const Package = () => {


    // const location = useLocation();
    // const [category, setCategory] = useState('');
    // const [keyword, setKeyword] = useState('');
    // const [brandKey, setBrandKey] = useState('');

    const [pack, setPack] = useState([])
    const [packageproduct, setPackageProduct] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [availablePackageIds, setAvailablePackageIds] = useState([]);

    const [isAvailable, setIsAvailable] = useState(false);
    const [packageavail, setPackageAvail] = useState([]);
    useEffect(() => {
        getData()
        fetchPackages();
    }, [])


    const getData = async () => {
        try {
            const res = await Axios.get('/api/package')
            setPack(res.data)
            setPackageProduct(res.product || []);

            console.log(res.data, "data package")
            // Filter `pack` based on `packageavail`

        } catch (error) {
            console.log(error)

        }
    }



    useEffect(() => {
        if (packageproduct.length > 0) {
            fetchProductNames()
        }
    }, [packageproduct])
    const fetchProductNames = async () => {
        try {
            const productPromises = packageproduct.map((productId) =>
                Axios.get(`/api/products/${productId}`).then((response) => response.data.name)
            );
            const names = await Promise.all(productPromises);
            setProductNames(names);
        } catch (error) {
            console.error("Error fetching product names:", error);
        }
    };




    const fetchPackages = async () => {
        try {
            const res = await Axios.get(`/api/package/check-availability`);
            console.log(res)

            // Extract available package IDs
            const availableIds = res.data.availablePackages.map(pkg => pkg.packageId);
            console.log(availableIds,"availavle iddddddddddd")
            setAvailablePackageIds(availableIds);
        } catch (error) {
            console.error("Error fetching packages:", error);
        }
    };

    console.log(availablePackageIds)

    const toggleAvailabilityFilter = () => {
        setIsAvailable(prev => !prev);
    };

    // Clear the filter and show all packages
    const clearFilter = () => {
        setIsAvailable(false);
    };



    return (
        <body className="template-index index-demo4">
            <div className="page-wrapper">
                <div className="classic-topHeader">
                    <Header />
                    <div id="page-content" className="mb-0">
                        <div class="container">
                            {' '}
                            <div class="row" style={{ marginTop: '100px' }}>
                                {' '}
                                <div class="col-12 col-sm-12 col-md-12 col-lg-3 sidebar sidebar-bg filterbar">
                                    <div class="closeFilter d-block d-lg-none">
                                        <i class="icon anm anm-times-r"></i>
                                    </div>
                                    <div className=''>
                                        <Link to={'/packavail'}>
                                            <div className='btn btn-success mb-5'>
                                                Check month availability
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="sidebar-widget filterBox filter-widget">
                                        <div class="widget-title">
                                            <h2>Filter By</h2>
                                        </div>
                                        <div class="widget-content filterby filterDD">
                                            <a
                                                href="#;"
                                                class="btn btn-sm brd-link"
                                                onClick={(e) => clearFilter()}
                                            >
                                                Clear All
                                            </a>
                                        </div>
                                    </div>
                                    <div class="sidebar-widget clearfix categories filterBox filter-widget">
                                        <div class="widget-title">
                                            <h2>date filter</h2>
                                        </div>
                                        <div class="widget-content filterDD">
                                            <ul class="sidebar-categories scrollspy morelist clearfix">
                                                <li
                                                    // key={index}
                                                    class="lvl1 more-item"
                                                // onClick={() => handleFilterCategory(category._id)}
                                                >
                                                    <a href="#" class="site-nav">
                                                        {/* {category.categoryName}{' '} */}

                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-9 main-col">
                                    <div class="toolbar toolbar-wrapper shop-toolbar">
                                        <div class="row align-items-center">
                                            <div class="col-4 col-sm-2 col-md-4 col-lg-4 text-left filters-toolbar-item d-flex order-1 order-sm-0">
                                                <button
                                                    type="button"
                                                    class="btn btn-filter icon anm anm-sliders-hr d-inline-flex d-lg-none me-2"
                                                >
                                                    <span class="d-none">Filter</span>
                                                </button>

                                            </div>
                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 text-center product-count order-0 order-md-1 mb-3 mb-sm-0">
                                                <span class="toolbar-product-count">
                                                    Showing: {pack?.length} package
                                                </span>
                                            </div>
                                            <div class="col-8 col-sm-6 col-md-4 col-lg-4 text-right filters-toolbar-item d-flex justify-content-end order-2 order-sm-2">

                                            </div>
                                        </div>
                                    </div>

                                    <div id="productsingle" class="product-listview-loadmore">
                                        {pack?.length > 0 ? (
                                            pack?.map((pac, index) => (
                                                <div className="grid-products grid-view-items" key={index}>
                                                    <div className="row col-row product-options list-style">
                                                        <div className="product-box">
                                                            <div className="product-image">
                                                                <Link
                                                                    to={`/packagesingle/${pac._id}`}
                                                                    className="product-img rounded-0"
                                                                >
                                                                    <br></br>
                                                                    <br></br>
                                                                    <img
                                                                        className="rounded-0 blur-up lazyload"
                                                                        src={
                                                                            pac?.photo !== ''
                                                                                ? pac?.photo
                                                                                : 'assets/images/products/625x625.jpg'
                                                                        }
                                                                        alt="package photo"
                                                                        title="package"
                                                                        width="625"
                                                                        height="808"
                                                                    />
                                                                </Link>
                                                                <div className="saleTime" data-countdown="2025/01/01"></div>
                                                                <div className="button-set style1">
                                                                    <a
                                                                        href="#quickshop-modal"
                                                                        className="btn-icon addtocart quick-shop-modal"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#quickshop_modal"
                                                                    >
                                                                        <span
                                                                            className="icon-wrap d-flex-justify-center h-100 w-100"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="left"
                                                                            title="Quick Shop"
                                                                        >
                                                                            <i className="icon anm anm-cart-l"></i>

                                                                            <span className="text">Quick Shop</span>
                                                                        </span>
                                                                    </a>
                                                                    <a
                                                                        href="#quickview-modal"
                                                                        className="btn-icon quickview quick-view-modal"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#quickview_modal"
                                                                    >
                                                                        <span
                                                                            className="icon-wrap d-flex-justify-center h-100 w-100"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="left"
                                                                            title="Quick View"
                                                                        >
                                                                            <i className="icon anm anm-search-plus-l"></i>
                                                                            <span className="text">Quick View</span>
                                                                        </span>
                                                                    </a>
                                                                    <i className="icon anm anm-heart-l"></i>
                                                                    <span className="text">Add To Wishlist</span>
                                                                    <i className="icon anm anm-random-r"></i>
                                                                    <span className="text">Add to Compare</span>
                                                                </div>
                                                            </div>
                                                            <div className="product-details text-left">
                                                                <div className="product-name">{pac.name}</div>
                                                                <div className="product-price">
                                                                    <span className="price old-price">(₹{pac.price})</span>
                                                                    <span className="price">(₹{pac.specialPrice})</span>
                                                                </div>

                                                                <ul className="variants-clr swatches"></ul>
                                                                <div className="button-action hidden">
                                                                    <div className="addtocart-btn">
                                                                        <form className="addtocart" action="#" method="post">
                                                                            {/* <span
                                                                                className="text"
                                                                                style={{
                                                                                    color: availablePackageIds.includes(pac?._id)
                                                                                        ? 'green'
                                                                                        : 'red',
                                                                                }}
                                                                            >
                                                                                {availablePackageIds.includes(pac?._id)
                                                                                    ? 'Available Today'
                                                                                    : 'Unavailable Today'}
                                                                            </span> */}
                                                                            <br />
                                                                            <Link
                                                                                to={`/packagesingle/${pac._id}`}
                                                                                className="btn btn-md quick-shop quick-shop-modal"
                                                                            >
                                                                                <span>view more</span>

                                                                            </Link>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="align-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </div>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </body>
    );
};

export default Package;
