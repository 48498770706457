import Axios from '../axios/axios';
import {
  NEW_ARRIVALS_HOME_PAGE_LIST_REQUEST,
  NEW_ARRIVALS_HOME_PAGE_LIST_SUCCESS,
  NEW_ARRIVALS_HOME_PAGE_LIST_FAIL,
  SHOP_CATEGORY_HOME_PAGE_LIST_REQUEST,
  SHOP_CATEGORY_HOME_PAGE_LIST_SUCCESS,
  SHOP_CATEGORY_HOME_PAGE_LIST_FAIL,
  BANNER_SESSION_HOME_PAGE_LIST_REQUEST,
  BANNER_SESSION_HOME_PAGE_LIST_SUCCESS,
  BANNER_SESSION_HOME_PAGE_LIST_FAIL,
  BRAND_SESSION_HOME_PAGE_LIST_REQUEST,
  BRAND_SESSION_HOME_PAGE_LIST_SUCCESS,
  BRAND_SESSION_HOME_PAGE_LIST_FAIL

} from '../constants/homePageConstant';



export const getNewArrivals = () => async (dispatch) => {
  try {
    dispatch({ type: NEW_ARRIVALS_HOME_PAGE_LIST_REQUEST });

    const { data } = await Axios.get(`/api/products`);

    dispatch({
      type: NEW_ARRIVALS_HOME_PAGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_ARRIVALS_HOME_PAGE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};





export const getCategoryList = () => async (dispatch) => {
  try {
    dispatch({ type: SHOP_CATEGORY_HOME_PAGE_LIST_REQUEST });

    const { data } = await Axios.get(`/api/category`);

    dispatch({
      type: SHOP_CATEGORY_HOME_PAGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SHOP_CATEGORY_HOME_PAGE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const bannerSession = () => async (dispatch) => {
  try {
    dispatch({ type: BANNER_SESSION_HOME_PAGE_LIST_REQUEST });

    const { data } = await Axios.get(`/api/banner`);

    dispatch({
      type: BANNER_SESSION_HOME_PAGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BANNER_SESSION_HOME_PAGE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const brandSession = () => async (dispatch) => {
  try {
    dispatch({ type: BRAND_SESSION_HOME_PAGE_LIST_REQUEST });

    const { data } = await Axios.get(`/api/brand`);

    dispatch({
      type: BRAND_SESSION_HOME_PAGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BRAND_SESSION_HOME_PAGE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};