//this is product list  page

import React, { useState, useEffect } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/Header';
import { getProductsList } from '../../actions/ProductPageAction';
import { Link } from 'react-router-dom';

import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { brandSession } from '../../actions/HomePageActions';
import { getCategoryList } from '../../actions/HomePageActions';
import { useLocation } from 'react-router-dom';


const Product = () => {



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const categoryList_ = useSelector((state) => state.homePageCategoryList);
  const { categoryList } = categoryList_;

  const brandSession_ = useSelector((state) => state.homePageBrand);
  const { brand } = brandSession_;
  console.log(brand)

  const products_ = useSelector((state) => state.productList);
  const { products } = products_;
  console.log(products)

  const [categoryFilter, setCategoryFilter] = useState('');
  const [brandFilter, setBrandFilter] = useState('');

  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState('?');

  const { id } = useParams();
  const [category, setCategory] = useState('');
  const [keyword, setKeyword] = useState('');
  const [brandKey, setBrandKey] = useState('');
  // const queryParameters = new URLSearchParams(window.location.search);
  // const category = queryParameters.get('category');
  // const keyword = queryParameters.get('keyword');
  // const brandKey = queryParameters.get('brand');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCategory(params.get('category') || '');
    setKeyword(params.get('keyword') || '');
    setBrandKey(params.get('brand') || '');
  }, [location.search]);

  console.log(keyword);

  // useEffect(() => {
  //   dispatch(getCategoryList());
  //   dispatch(brandSession());
  //   dispatch(getProductsList());
  //   setCategoryFilter('');
  //   setBrandFilter('');
  //   const result = '';
  //   dispatch(getProductsList(result));
  //   // navigate(result);
  // }, []);

  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(brandSession());
  }, [dispatch]);

  useEffect(() => {
    let result = '?';
    if (category) {
      result += `category=${category}`;
    }
    if (keyword) {
      result += `${result.length > 1 ? '&' : ''}keyword=${keyword}`;
    }
    if (brandKey) {
      result += `${result.length > 1 ? '&' : ''}brand=${brandKey}`;
    }
    dispatch(getProductsList(result));
  }, [category, keyword, brandKey, dispatch]);

  const clearFilter = () => {
    setCategoryFilter('');
    setBrandFilter('');
    setUrlParams('')
    // const result = '';
    dispatch(getProductsList(''));
    navigate('/products');
  };

  const handleFilterCategory = (val) => {
    setCategoryFilter(val);
    const params= new URLSearchParams(urlParams)
    params.set('category',val)
    const result = `?${params.toString()}`;
    setUrlParams(result);
    dispatch(getProductsList(result));
    navigate(result);
  };

  const handleFilterBrand = (val) => {
    setBrandFilter(val);
    const params= new URLSearchParams(urlParams)
    params.set('brand',val)
    const result = `?${params.toString()}`;
    setUrlParams(result);
    dispatch(getProductsList(result));
    navigate(result);
  };

  return (
    <body className="template-index index-demo4">
      <div className="page-wrapper">
        <div className="classic-topHeader">
          <Header />
          <div id="page-content" className="mb-0">
            <div class="container">
              {' '}
              <div class="row" style={{ marginTop: '100px' }}>
                {' '}
                <div class="col-12 col-sm-12 col-md-12 col-lg-3 sidebar sidebar-bg filterbar">
                  <div class="closeFilter d-block d-lg-none">
                    <i class="icon anm anm-times-r"></i>
                  </div>
                  <div class="sidebar-tags sidebar-sticky clearfix">
                    <div class="sidebar-widget filterBox filter-widget">
                      <div class="widget-title">
                        <h2>Filter By</h2>
                      </div>
                      <div class="widget-content filterby filterDD">
                        <a
                          href="#;"
                          class="btn btn-sm brd-link"
                          onClick={(e) => clearFilter()}
                        >
                          Clear All
                        </a>
                      </div>
                    </div>

                    <div class="sidebar-widget clearfix categories filterBox filter-widget">
                      <div class="widget-title">
                        <h2>Categories</h2>
                      </div>
                      <div class="widget-content filterDD">
                        <ul class="sidebar-categories scrollspy morelist clearfix">
                          {categoryList?.categories?.map((category, index) => (
                            <li
                              key={index}
                              class="lvl1 more-item"
                              onClick={() => handleFilterCategory(category._id)}
                            >
                              <a href="#" class="site-nav">
                                {category.categoryName}{' '}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div class="sidebar-widget filterBox filter-widget brand-filter">
                      <div class="widget-title">
                        <h2>Brands</h2>
                      </div>
                      <div class="widget-content filterDD">
                        <ul class="clearfix">
                          {brand?.brands?.map((brand, index) => (
                            <li onClick={(e) => handleFilterBrand(brand._id)}>
                              <a href="#" class="site-nav">
                                {brand.brandName}{' '}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-9 main-col">
                  <div class="toolbar toolbar-wrapper shop-toolbar">
                    <div class="row align-items-center">
                      <div class="col-4 col-sm-2 col-md-4 col-lg-4 text-left filters-toolbar-item d-flex order-1 order-sm-0">
                        <button
                          type="button"
                          class="btn btn-filter icon anm anm-sliders-hr d-inline-flex d-lg-none me-2"
                        >
                          <span class="d-none">Filter</span>
                        </button>
                        {/* <div class="filters-item d-flex align-items-center">
                          <label class="mb-0 me-2 d-none d-lg-inline-block">
                            View as:
                          </label>
                          <div class="grid-options view-mode d-flex">
                            <a
                              class="icon-mode mode-list d-block active"
                              data-col="1"
                            ></a>
                          </div>
                        </div> */}
                      </div>
                      <div class="col-12 col-sm-4 col-md-4 col-lg-4 text-center product-count order-0 order-md-1 mb-3 mb-sm-0">
                        <span class="toolbar-product-count">
                          Showing: {products?.length} products
                        </span>
                      </div>
                      <div class="col-8 col-sm-6 col-md-4 col-lg-4 text-right filters-toolbar-item d-flex justify-content-end order-2 order-sm-2">
                        {/* <div class="filters-item d-flex align-items-center">
                          <label
                            for="ShowBy"
                            class="mb-0 me-2 text-nowrap d-none d-sm-inline-flex"
                          >
                            Show:
                          </label>
                          <select
                            name="ShowBy"
                            id="ShowBy"
                            class="filters-toolbar-show"
                          >
                            <option value="title-ascending" selected="selected">
                              All
                            </option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                          </select>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div id="productsingle" class="product-listview-loadmore">
                    {products?.length > 0 ? (
                      products?.map((product, index) => (
                        <div class="grid-products grid-view-items">
                          <div class="row col-row product-options list-style">
                            <div class=" ">
                              <div class="product-box">
                                <div class="product-image">
                                  <Link
                                    to={`/product/${product._id}`}
                                    class="product-img rounded-0"
                                  >
                                    <br></br>
                                    <br></br>
                                    <img
                                      class="rounded-0 blur-up lazyload"
                                      src={
                                        product?.image1 != ''
                                          ? product?.image1
                                          : 'assets/images/products/625x625.jpg'
                                      }
                                      alt="Product"
                                      title="Product"
                                      width="625"
                                      height="808"
                                    />
                                  </Link>

                                  <div
                                    class="saleTime"
                                    data-countdown="2025/01/01"
                                  ></div>

                                  <div class="button-set style1">
                                    <a
                                      href="#quickshop-modal"
                                      class="btn-icon addtocart quick-shop-modal"
                                      data-bs-toggle="modal"
                                      data-bs-target="#quickshop_modal"
                                    >
                                      <span
                                        class="icon-wrap d-flex-justify-center h-100 w-100"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="left"
                                        title="Quick Shop"
                                      >
                                        <i class="icon anm anm-cart-l"></i>
                                        <span class="text">Quick Shop</span>
                                      </span>
                                    </a>

                                    <a
                                      href="#quickview-modal"
                                      class="btn-icon quickview quick-view-modal"
                                      data-bs-toggle="modal"
                                      data-bs-target="#quickview_modal"
                                    >
                                      <span
                                        class="icon-wrap d-flex-justify-center h-100 w-100"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="left"
                                        title="Quick View"
                                      >
                                        <i class="icon anm anm-search-plus-l"></i>
                                        <span class="text">Quick View</span>
                                      </span>
                                    </a>

                                    <i class="icon anm anm-heart-l"></i>
                                    <span class="text">Add To Wishlist</span>

                                    <i class="icon anm anm-random-r"></i>
                                    <span class="text">Add to Compare</span>
                                  </div>
                                </div>
                                <div class="product-details text-left">
                                  <div class="product-name">{product.name}</div>

                                  <div class="product-price">
                                    <span class="price old-price">
                                      {product.price}
                                    </span>
                                    <span class="price">
                                      (₹{product.offerPrice})
                                    </span>
                                  </div>

                                  <p class="sort-desc hidden">
                                    {product.description}
                                  </p>

                                  <ul class="variants-clr swatches"></ul>

                                  <div class="button-action hidden">
                                    <div class="addtocart-btn">
                                      <form
                                        class="addtocart"
                                        action="#"
                                        method="post"
                                      >
                                        <Link
                                          to={`/product/${product._id}`}
                                          class="btn btn-md quick-shop quick-shop-modal"
                                        >
                                          <span class="text">Quick Shop</span>
                                        </Link>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='align-center'>
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    )}

                    {/* <div class="infinitpaginOuter text-center mt-5">
                      <div class="infiniteload">
                        <a href="#" class="btn btn-xl loadMoreList">
                          Load More
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </body>
  );
};

export default Product;
